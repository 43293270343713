body {
  margin: 0;
}

:root {
  scrollbar-color: rebeccapurple #222;
  scrollbar-width: thin;
}

.no-scroll {
  overflow: hidden;
}